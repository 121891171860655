import 'core-js';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import store from "./redux/store";
import './services/i18n';

import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://10b36ac0fc60b851f0856181ec3875ab@o4508600833605632.ingest.de.sentry.io/4508600840486992",
    integrations: [],
});


ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
